<template>
  <div>
    <nav-bar
      v-if="showBackBtn"
      @onBack="handleBack"
    />
    <div
      v-if="isClose"
    >
      <div v-if="validateParams" class="activityTask">
        <h3>{{ needObscure ? OBSCURE_NAME : activityName }}</h3>
        <div>{{ effectiveTime }}</div>
        <div>{{ needObscure ? '测试专用' : note }}</div>

        <div class="upload-module">
          <div v-if="uploadOptions">
            <div class="task-upload" v-for="(item, index) in uploadEntries" :key="index">
              <div class="task-upload-file">
                <div class="upload-top flex-box flex-vertical-center">
                  <div class="title flex0">{{item.text}}</div>
                  <div class="upload-tips flex1">
                    <span>{{ getUploadTip(item.handleType) }}</span>
                  </div>
                </div>
                <div>
                  <image-collection
                    v-if="uploadOptions"
                    ref="uploadImg"
                    :isDisabled="!!item.openFlag"
                    :maxCount="item.handleDataType === activityOrderType ? 1 : undefined"
                    :disabledText="disabledText"
                    :uploadOptions="uploadOptions"
                    :accept="getUploadAccept(item.handleType)"
                    :task-id="urlParams.userTaskId"
                    :job-id="urlParams.jobId"
                    :check-repeat="true"
                    :job-start-time="jobStartTime"
                    :isShowAddBtn="true"
                    :isCheckTime="true"
                    :invalid-num="invalidNum"
                    :imageType="item.handleDataType"
                    :video-limit="item.isActivity"
                    :video-limit-count="videoLimitCount"
                    @file-change="onFileChange"
                  />
                </div>
              </div>
            </div>
            <div class="submit">
              <van-button
                class="skuTaskSubmit"
                :loading="loading"
                loading-text="提交中..."
                @click="handleSubmit"
              >
                点我提交!
              </van-button>
              <span class="feedback">如无法完成任务，请<span class="linkText" @click="clickFeedback">点击此处</span>反馈</span>
            </div>
          </div>
          <div v-else>{{uploadTips}}</div>
        </div>
        <van-dialog
          title="【提交内容】"
          v-model="isShowConfirm"
          confirm-button-text="确认提交"
          cancel-button-text="我再想想"
          :show-cancel-button="true"
          @confirm="confirmSubmit"
          @cancel="cancelSubmit"
        >
          <div class="confirm-content">
            <p class="count">{{confirmMessageStr}}</p>
            <div class="confirm-again">【请再次确认以下信息是否正确】</div>
            <p>城市：{{cityName}}</p>
            <p>用户类型：{{userLevel}}</p>
          </div>
        </van-dialog>
      </div>
      <paramsError v-else />
    </div>

    <van-empty
      v-else
      description
    />
    <!-- 质检结果check -->
    <van-dialog
      title="质检不合格"
      v-model="isShowInvalidDialog"
      :show-confirm-button="false"
    >
      <div class="invalidContent">
        <p>{{ `不合格原因：${errorCause}`}}</p>
        <div>请联系管理员确认质检情况后，再继续任务。</div>
      </div>
      <div class="buttons">
        <van-button 
          class="refresh-button"
          type="info"
          @click="refreshPage"
        >
            刷新当前页面
        </van-button>
        <div class="return-button" @click="handleBack">返回任务列表</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import imageCollection from '../ImageCollection/Index'
import NavBar from '../components/nav-bar.vue'
import paramsError from '../components/paramsError.vue'
import { getTaskDetails, getUserTaskValidStatus } from '@/apis'
import { formatDate } from '@/tools/date'
import { getStore, setStore } from '@/tools/storage'
import { ACTIVITY_ORDER_UNIQUEID_LIST, ACTIVITY_ORDER_TYPE, UPLOAD_FILE_UNIQUEID_LIST } from '@/constant'
import { checkUploadImages } from '../shared'
import { Toast } from 'vant'
import backListMixins from '../mixins/back-list'
import submitFileMixins from '../mixins/submit-file'

const OBSCURE_KEYWORD = '脱敏'
const HANDLETYPE_MAP = {
  1: {
    accept: 'video/*',
    tip: '仅支持视频，不超过 500M',
  },
  2: {
    accept: 'video/*',
    tip: '仅支持视频，不超过 500M',
  },
  3: {
    accept: 'image/*',
    tip: '仅支持图片，不超过 50M',
  },
  4: {
    accept: 'video/*,image/*',
    tip: '图片不超过 50M，视频不超过 500M',
  },
}

export default {
  name: 'SkuTask',
  components: {
    imageCollection,
    NavBar,
    paramsError,
  },
  mixins: [backListMixins, submitFileMixins],
  data() {
    return {
      activityName: '',
      effectiveTime: '',
      loading: false,
      companyId: '',
      companyName: '',
      isClose: true,
      accept: '',
      taskStartTime: 0,
      taskEndTime: 0,
      note: '',
      cityName: '',
      userLevel: '',
      userCode: '',
      districtName: '',
      showAutoRecordingButton: false,
      title: '',
      OBSCURE_NAME: '录屏事件名称',
      needObscure: false,
      recordAppFlag: 1,
      uploadOptions: null,
      uploadTips: '',
      taskType: '',
      jobStartTime: 0,
      uploadEntries: [],
      isShowConfirm: false,
      submitInfo: null,
      submitImgs: null,
      confirmMessage: null,
      disabledText: '图片已提交，不可重传',
      activityOrderType: ACTIVITY_ORDER_TYPE,
      closeEntrances: [],
      isShowInvalidDialog: false,
      errorCause: '',
      invalidNum: '',
      videoLimitCount: 1,
    }
  },
  computed: {
    confirmMessageStr() {
      if(!this.confirmMessage || !this.confirmMessage.length) {
        return ''
      }
      return this.confirmMessage.join('，')
    }
  },
  async created() {
    this.initEntries()
  },

  destroyed() {
    document.title = ''
  },

  methods: {
    // 初始化入口
    async initEntries() {
      try {
        this.isUserTaskValid()
        const { extendData } = this.urlParams
        const { handleList } = JSON.parse(extendData)
        this.uploadEntries = JSON.parse(handleList)
        if (this.uploadEntries && this.uploadEntries.length) {
          this.uploadEntries.forEach(item => {
            item.isActivity = item.text.includes('活动')
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    // userTask是否禁止上传
    async isUserTaskValid() {
      try {
        const { userTaskId } = this.urlParams
        const res = await getUserTaskValidStatus({ userTaskId })
        if(res && res.code === 0) {
          const { userTaskUploadStatus, errorCause, submitOrderDeleteNumber } = res.data || {}
          this.isShowInvalidDialog = !userTaskUploadStatus
          this.errorCause = errorCause
          this.invalidNum = submitOrderDeleteNumber
          this.removeLocalStorage(userTaskId)
        } else {
          this.isShowInvalidDialog = false
          Toast.fail(res?.msg || '获取任务质检状态失败')
        }
      } catch(e) {
        Toast.fail(e?.msg || '获取任务质检状态失败')
        this.isShowInvalidDialog = false
      }
    },
    /**
     * @description: 根据taskid清除缓存
     * @param {*} userTaskId
     * @return {*}
     */    
     removeLocalStorage(userTaskId) {
      const uniqueList = getStore(UPLOAD_FILE_UNIQUEID_LIST) || []
      const activityOrderUniqueList = getStore(ACTIVITY_ORDER_UNIQUEID_LIST) || []
      const uniqueListNew = uniqueList.filter(item => {
        const splitItem = item.split('_')
        // 删除 同一taskId下与num不同的【所有】缓存      
        return !(splitItem[2] === userTaskId && splitItem[3] !== `${this.invalidNum}`)
      })
      const activityOrderUniqueListNew = activityOrderUniqueList.filter(item => {
        const splitOrder= item.split('_')
        return !(splitOrder[0] === userTaskId && splitOrder[1] !== `${this.invalidNum}`)
      })

      setStore(UPLOAD_FILE_UNIQUEID_LIST,uniqueListNew)
      setStore(ACTIVITY_ORDER_UNIQUEID_LIST,activityOrderUniqueListNew)
    },
    checkIsOpenOrderUpload() {
      const { userTaskId } = this.urlParams
      let activityOrderUniqueList = getStore(ACTIVITY_ORDER_UNIQUEID_LIST) || []
      const uniqueId = `${userTaskId}_${this.invalidNum}`
      this.uploadEntries.forEach(item => {
        if (this.closeEntrances.includes(item.handleDataType)
            || (activityOrderUniqueList.includes(uniqueId) && item.handleDataType === ACTIVITY_ORDER_TYPE)
        ) {
          item.openFlag = 1;
        }
      })
    },
    // 获取图片列表
    getImgsList() {
      let imgs = []
      this.uploadEntries.forEach((item, index) => {
        imgs = imgs.concat(this.$refs.uploadImg[index].getImagesList())
      })
      return imgs
    },
    // 获取accept类型
    getUploadAccept(handleType) {
      return HANDLETYPE_MAP[+handleType]?.accept ?? '*'
    },
    // 获取上传提示
    getUploadTip(handleType) {
      return HANDLETYPE_MAP[+handleType]?.tip ?? ''
    },
    // 获取任务详情
    async getJobDetails() {
      try {
        const { userTaskId, status } = this.urlParams
        const res = await getTaskDetails({ userTaskId, status: status ? +status : undefined })
        if (res) {
          const data = res.data || {}
          if (res.code !== 0) return Toast.fail(res.msg || '获取任务详情接口返回错误')
          const time = formatDate(new Date(data.jobEndTime), 'yyyy-MM-dd HH:mm:ss')
          if (data.note) {
            this.note = data.note
          }
          this.needObscure = !!data.isSensitive
          this.cityName = data.cityName || ''
          this.userLevel = data.userLevel || ''
          this.districtName = data.districtName || ''
          this.activityName = data.name || ''
          this.userCode = data.userCode || ''
          if (data.name) {
            if (data?.name?.indexOf(OBSCURE_KEYWORD) > -1 || this.needObscure) {
              document.title = this.OBSCURE_NAME
            } else {
              document.title = data.name
            }
          }
          if (data.recordAppFlag === 2) {
            this.recordAppFlag = 2
          }
          this.effectiveTime = `有效时间至:${time}`
          this.companyId = data.companyId
          this.companyName = data.companyName || ''
          this.taskType = data.taskType
          this.jobStartTime = data.jobStartTime
          this.closeEntrances = data.closeEntrances || []
          this.$nextTick(() => {
            this.checkIsOpenOrderUpload()
          })
          if (data.detail) {
            const details = JSON.parse(data.detail)
            this.companyId = details.companyId
          }
        }
      } catch (err) {
        Toast.fail(err.message || '获取任务详情接口请求错误')
      }
    },
    onFileChange() {
      this.taskStartTime = Date.now()
    },
    async confirmSubmit() {
      try {
        await this.fetchTaskNew(this.submitInfo, this.submitImgs, 'activity', this.invalidNum)
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    cancelSubmit() {
      this.loading = false
    },
    // 提交
    async handleSubmit() {
      if (this.loading) return
      await this.isUserTaskValid()
      if(this.isShowInvalidDialog){
        return 
      }
      this.loading = true
      try {
        const imgs = this.getImgsList()
        const submitInfo = await checkUploadImages(imgs)
        if (submitInfo) {
          const validMap = {}
          const confirmMessage = []
          for (let i in this.uploadEntries) {
            const { text, handleDataType} = this.uploadEntries[i]
            validMap[text] = await this.$refs.uploadImg[i].checkFileValid()
            const entrys = imgs.filter((item) => item.imageType === handleDataType)
            const entryLen = entrys.length
            entrys.forEach(item => {
              item.entryLen = entryLen
            })
            if (entryLen > 0) {
              confirmMessage.push(`${text}×${entryLen}`)
            }
          }
          this.confirmMessage = confirmMessage
          for (let i in validMap) {
            const verifyValid = validMap[i]
            if (!verifyValid.valid) {
              Toast.fail(verifyValid.messageType ? verifyValid.message : `${i}模块${verifyValid.message}，请重新上传后提交`)
              this.loading = false
              return
            }
          }
          this.submitInfo = submitInfo
          this.submitImgs = imgs
          this.$nextTick(() => {
            this.isShowConfirm = true
          })
        } else {
          this.loading = false
        }
      } catch(e) {
        console.log(e)
        this.loading = false
      }
    },
    clickFeedback() {
      this.$router.push({ path: '/abnormalFeedback'+ location.search});
    },
    refreshPage() {
      this.$router.go(0);
    }
  },

}
</script>

<style lang="less" scoped>
.activityTask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-size: 16px;
  .upload-module {
    width: 100%;
    padding-bottom: 60px;
    .task-upload {
      width: calc(100% - 30px);
      padding: 15px;
      margin-top: 25px;
      font-size: 14px;
      background-color: #e2e2e3;
      border-radius: 20px;
      .task-upload-file {
        .upload-top {
          padding-bottom: 15px;
          .title {
            font-size: 16px;
            font-weight: 800;
          }
          .upload-tips {
            font-size: 12px;
            color: #999;
            text-align: right;
          }
        }
      }
    }
  }
  .confirm-content {
    text-align: center;
    .confirm-again {
      font-weight: 500;
    }
  }
  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(255,255,255,0) 0%,
      rgba(255,255,255,0.5) 25%,
      rgba(255,255,255,1) 50%,
      rgba(255,255,255,1) 100%);
    .skuTaskSubmit {
      width: 80%;
      color: #fff;
      background-color: #007aff;
      border-radius: 16px;
    }
    .feedback {
      padding: 10px 0 20px 0;
      font-size: 14px;
      text-align: center;
      .linkText{
        font-weight: bold;
        color: #3e7bf3
      }
    }
  }
}
.invalidContent {
  text-align: center;
  font-size: 14px;
  margin: 15px 0px;
}
.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  .refresh-button {
    width: 80%;
    margin-top: 5px;
    border-radius: 10px;
  }
  .return-button {
    color: #3e7bf3;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 16px;
  }
}
</style>
