import { render, staticRenderFns } from "./activity-task.vue?vue&type=template&id=5354a4f8&scoped=true&"
import script from "./activity-task.vue?vue&type=script&lang=js&"
export * from "./activity-task.vue?vue&type=script&lang=js&"
import style0 from "./activity-task.vue?vue&type=style&index=0&id=5354a4f8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5354a4f8",
  null
  
)

export default component.exports